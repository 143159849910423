// CTA V1
.cta {
  &__inner {
    text-align: center;
    padding: 40px;
    border-radius: 5px;
  }

  &-form-box {
    margin-top: 30px;

    form {
      text-align: center;
    }

    input {
      height: 60px;
      width: 100%;
      border: none;
      padding: 10px;
      margin-bottom: 15px;
      background-color: $color-light;
      border-bottom: 2px solid transparent;
    }

    input:focus {
      outline: none;
      border: none;
      border-bottom: 2px solid $color-primary;
    }

    button {
      height: 60px;
    }
  }
}

.showreel {
  &__inner {
    text-align: center;
    padding: 40px;
    border-radius: 5px;
  }

  &-form-box {
    margin-top: 30px;

    form {
      text-align: center;
    }

    input {
      height: 60px;
      width: 100%;
      border: none;
      padding: 10px;
      margin-bottom: 15px;
      background-color: $color-light;
      border-bottom: 2px solid transparent;
    }

    input:focus {
      outline: none;
      border: none;
      border-bottom: 2px solid $color-primary;
    }

    button {
      height: 60px;
    }
  }
}

//  CTA V2
.cta2 {

  background-repeat: no-repeat;
  background-size: cover;

  &__wrapper {
    background-color: $bg-one;
    padding: 40px;
    border-radius: 10px;

    @include respond(laptop) {
      padding: 20px;
    }
  }

  form {
    @include respond(laptop) {
      display: inline-block;
    }

    input {
      border: 0;
      border-radius: 5px;
      box-shadow: 2px 0px 15px 5px $color-shadow;
      padding: 15px 15px;
      width: 300px;

      &:focus {
        outline: none;
      }

      @include respond(laptop) {
        width: 250px;
      }

      @include respond(phone) {
        width: auto;
      }
    }

    button {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }

    @media screen and (max-width: 450px) {
      input {
        width: 100%;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }

      button {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

// Cta v3
.ctav3 {
  &__content {
    text-align: center;
    position: relative;

    h4 {
      line-height: 1.5;
    }

    h2 {
      a {
        color: $color-primary;

        &:hover {
          color: $color-secondary;
        }
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);

      svg {
        opacity: 0.3;
        color: $color-primary;
        font-size: 150px;
      }
    }
  }
}

// Cta v4
.ctav4 {
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-light;
  position: relative;

  @include respond(tablet) {
    text-align: center;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: $color-dark;
  }

  &__content {
    z-index: 1;

    h3 {
      color: $color-light;
    }
  }

  &__video-btn {
    position: relative;
    top: 50%;

    @include respond(tablet) {
      padding-top: 70px;
    }
  }
}

// Cta v5
.ctav5 {
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-light;
  position: relative;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: $color-dark;
  }

  &__content {
    z-index: 1;

    h2 {
      color: $color-light;
      margin-bottom: 25px;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      justify-content: center;
      gap: 8px;
      margin-bottom: 28px;

      li {
        list-style: none;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $bg-one;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
      }
    }
  }
}

// Showreel 
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* Set the aspect ratio (height:width) of the video */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}