// Fonts font
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Font Family 
$font-heading: "Cormorant+Garamond", serif;
$font-display: "Raleway", sans-serif;

body {
  font-family: $font-display;
  line-height: 1.5;
  color: $color-gray;
}
p {
  line-height: 1.8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  color: $color-dark;
  font-weight: 400;
}
.display-1,
.display-2,
.display-3,
.display-4 {
  font-weight: 400;
  font-style: italic;
  color: $color-title;
}
.display-5,
.display-6 {
  font-weight: 700;
}
