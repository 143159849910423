/*
* Colors
*/
// $color-primary: #c27b7f;
$color-primary: #4b4646;
// $color-secondary: #876585;
$color-secondary: #9fa292;
// $color-tertiary: #fcb0b4;
$color-tertiary: #e6e6e6;


$color-light: #ffffff;

// Main Text colour
// $color-dark: #2f4858; 
$color-dark: #7c7771;

$color-title: #7c7771;

$color-gray: #676768;
$color-border: #eff0f7;
$color-success: #00b374;
$color-warning: #ffbb1c;
$color-danger: #ca3604;
$color-shadow: rgba(1, 6, 33, 0.06);
// $bg-one: #f5ebe6;
$bg-one: #f2f1f0;
// $bg-one: #e6e6e6; 

